import globalConfig from './globalConfig';
import { createAuth0Client } from '@auth0/auth0-spa-js';
import { handleNonGetHttpResponse, setPostMethod } from './http';

let _p = {
    createAuth0Client,
    globalConfig,
    clearAuth0Connection: () => delete _p.auth0Connection,
    fetch,
    handleNonGetHttpResponse,
    setPostMethod
};
export const _private = _p;

/**
 * This creates an Auth0 connection with the given parameters.
 * If the connection already exists, it is returned.
 * @param {string} protocol - e.g. 'https:'
 * @param {string} host - e.g. 'localhost:8888'
 * @param {string} groupPath - portion of the URL path that identifies the group
 * @returns {Promise<import('@auth0/auth0-spa-js').Auth0Client>}
 *
 * @example
 * const connection = await getConnection('https:', 'dev.redbeam.com', '/g/0');
 */
export async function getConnection(protocol, host, groupPath) {
    if (_p.auth0Connection != null) {
        return _p.auth0Connection;
    }
    if (protocol == null || host == null || groupPath == null) {
        throw new Error('getConnection requires protocol, host, and groupPath (at least on the first call)');
    }
    const { auth0 } = _p.globalConfig();
    const audience = `${protocol}//${host}/api`;
    const redirect_uri = `${protocol}//${host}${groupPath}/`;
    // Login and get user info
    _p.auth0Connection = _p.createAuth0Client({
        domain: auth0.AUTH0_DOMAIN,
        clientId: auth0.AUTH0_CLIENT_ID,
        authorizationParams: {
            audience,
            redirect_uri
        },
        useRefreshTokens: true,
        useRefreshTokensFallback: true,
        // Copied to the /dist path (served by the node server) from node_modules during webpack build.
        workerUrl: `${groupPath}/auth0-spa-js.worker.production.js`
    });
    return _p.auth0Connection;
}

export async function changePassword(email) {
    // The auth0-spa-js library does not support changing passwords.
    // This is supported by the auth0.js library, but I didn't want to include a whole new
    // library just for this one function.

    /*
    POST https://{yourDomain}/dbconnections/change_password
    Content-Type: application/json
    {
        "client_id": "{yourClientId}",
        "email": "EMAIL",
        "connection": "CONNECTION",
        "organization": "ORGANIZATION_ID"
    }
    */
    const domain = _p.globalConfig().auth0.AUTH0_DOMAIN;
    const data = {
        client_id: _p.globalConfig().auth0.AUTH0_CLIENT_ID,
        email,
        connection: _p.globalConfig().auth0.AUTH0_CONNECTION_NAME
    };
    const url = `https://${domain}/dbconnections/change_password`;
    const params = await _p.setPostMethod(data, {}, false, false);
    const response = await _p.fetch(url, params);
    return _p.handleNonGetHttpResponse(response, url, 'POST');
}
