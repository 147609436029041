import { useEffect, useRef } from 'react';
import { hooks } from 'lib_ui-primitives';
import useEventSink from './useEventSink';
import logging from '@sstdev/lib_logging';

const { useRouter } = hooks;

const ROOT_ONLY_PATH = new RegExp('^/$');
const GROUP_ONLY_PATH = new RegExp('^/g/[0-9]+/$');
const EMPTY_PATH = new RegExp(ROOT_ONLY_PATH.source + '|' + GROUP_ONLY_PATH.source);

// This is used to manage navigation in the rules engine.
// - willNavigate_application_route will be called BEFORE the application renders the new
// route.  This is good place to perform cleanups (e.g. ask the user if they are sure
// they want to navigate when they have dirty data that hasn't been saved).
// - doingNavigate_application_route will release the router to finish the transition and
// render the new route.
// - didNavigate_application_route will log route changes that have occurred.  This is a good
// place to put actions that should occur after a navigation.
//
// This is done by blocking the navigation (using router.history.block()) and passing
// the method to release the block (doRouteChange) into the data of the navigate_application_route
// event publication.
export default function useNavigation() {
    //whether or not the page was bookmarked or reloaded
    const wasDirectNavigation = useRef(true);
    const eventSink = useEventSink();
    const router = useRouter();

    useEffect(() => {
        // Do not block if current route is a root location.
        if (router.isRoot()) return;
        const unsubscribes = beginBlock(router, eventSink);
        if (wasDirectNavigation.current) {
            if (EMPTY_PATH.test(router.location.pathname)) {
                wasDirectNavigation.current = false;
            } else {
                //in case or direct navigation to this page
                //either through bookmarking, or reloading
                //we need to run specifically the didNavigate logic
                //At this point, I see no problem with running the whole workflow,
                //except without a real doRouteChange
                const [, publish] = eventSink;
                publish(
                    {
                        navigate: {
                            // Called by rules engine to release navigation
                            //In case of a bookmarked page, or reload, this is a noop
                            doRouteChange: () => {},
                            //router object already has the correct location
                            router
                        }
                    },
                    {
                        verb: 'navigate',
                        namespace: 'application',
                        relation: 'route',
                        routePath: router.location.pathname
                    },
                    { waitForSubscriber: true, bubbleAlways: true }
                );
            }
        }
        return unsubscribes;
        //we do NOT want this run re-run when wasDirectNavigation changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router, eventSink]);
}

function beginBlock(router, eventSink) {
    const [, publish] = eventSink;
    const unsubscribes = [];
    // Block navigation until the rules engine releases it.
    logging.debug(`[NAVIGATION] starting blocking. Route is ${router.location.pathname}.`);
    const unblockRef = {};
    // Create some indirection so we can monkey patch this.
    unblockRef.unblockOriginal = router.history.block(function (location) {
        logging.debug(`[NAVIGATION] blocked ${location.pathname}. Route is ${router.location.pathname}.`);

        // Inform the rules engine that navigation has been requested.
        publish(
            {
                navigate: {
                    // Called by rules engine to release navigation
                    doRouteChange: () => {
                        // Begin blocking again after the navigation has finished.
                        const stopListening = router.history.listen(() => {
                            beginBlock(router, eventSink);
                            stopListening();
                            // remove stopListening from unsubscribes so it isn't called by accident
                            unsubscribes.splice(unsubscribes.indexOf(stopListening), 1);
                        });
                        // stop listening if this hook is unmounted.
                        unsubscribes.push(stopListening);

                        unblockRef.unblock();
                        // remove unblock from unsubscribes so it isn't called by accident
                        unsubscribes.splice(unsubscribes.indexOf(unblockRef.unblock), 1);
                        // Go to the requested location now that the rules engine has
                        // given us the go-ahead.
                        let targetPath = location.pathname;
                        if (location.search) {
                            targetPath += location.search;
                        }
                        router.goToLocation(targetPath);
                    },
                    //router object hasn't updated the location YET. So pass in the correct location
                    router: { ...router, location }
                }
            },
            {
                verb: 'navigate',
                namespace: 'application',
                relation: 'route',
                routePath: location.pathname
            },
            { bubbleAlways: true }
        );
        // cancel the transition until `doRouteChange` (above) is called.  At this time,
        // that will probably be from the doingNavigation_application_route rule.
        return false;
    });
    // Monkey-patch unblock so we can log it.
    unblockRef.unblock = () => {
        logging.debug(`[NAVIGATION] unblocking. Route is ${router.location.pathname}.`);
        unblockRef.unblockOriginal();
    };
    // unblock if this hook is unmounted
    unsubscribes.push(unblockRef.unblock);

    // This includes unblock and router history listen unsubscribe
    return () => {
        unsubscribes.forEach(u => u());
    };
}
